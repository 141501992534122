import { Component, Vue, Watch, Prop } from 'vue-property-decorator';
import { AxiosError } from 'axios';
import ErrorHandler from '@/support/ErrorHandler';
import { Setting } from '@/models/Setting';
import { formatDate } from '@/support/String';
import { cloneDeep } from 'lodash';

@Component<MiSettings>({})
export default class MiSettings extends Vue {
  @Prop()
  protected settings!: tcmgSetting[];

  @Prop()
  protected dmz!: string;

  protected visibleSettings: tcmgSetting[] = [];

  public $pageTitle = 'Instellingen';

  protected isLoading = true;

  protected menu = false;

  protected date: string | null = null;

  protected requested_planning_notifiable = false;

  public mounted() {
    this.emitBreadcrumb();
    this.initialize();
  }

  protected initialize() {
    this.getSettings();
  }

  protected getSettings() {
    this.isLoading = true;
    const model = this.dmz ? new Setting().dmz(this.dmz) : new Setting();

    model
      .all()
      .then((settings: Setting[]) => {
        this.visibleSettings = cloneDeep(this.settings);
        this.visibleSettings.forEach((visibleSetting: tcmgSetting) => {
          const setting = settings.find((setting: Setting) => setting.key === visibleSetting.key);

          if (setting) {
            if (visibleSetting.type === 'date') {
              visibleSetting.value = setting.value ? formatDate(setting.value as string, 'yyyy-LL-dd') : null;
            } else {
              visibleSetting.value = setting.value;
            }
          }
        });
      })
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  protected formattedDate(value: string) {
    if (! value) {
      return null;
    }

    return formatDate(value);
  }

  protected updateSetting(key: string, value: any) {
    const payload = [
      {
        key,
        value,
      },
    ];

    const model = this.dmz ? new Setting().dmz(this.dmz).update(payload) : new Setting().updateSetting(payload);

    model
      .catch((error: AxiosError) => {
        ErrorHandler.network(error);
      });
  }

  protected emitBreadcrumb() {
    this.$root.$emit('breadcrumbUpdated',
      {
        crumb: [
          {
            name: 'Instellingen',
            path: '/instellingen',
          },
        ],
      });
  }

  @Watch('$route', { deep: true })
  public routeChanged(to: any, from: any) {
    this.emitBreadcrumb();
  }
}

export interface tcmgSetting {
  key: string;
  type: string;
  value: any;
  label: string;
  description?: string;
  menu?: boolean;
  visible?: boolean;
}
