var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.isLoading ? _c("ALoader") : _vm._e(),
      !_vm.isLoading
        ? _c(
            "v-layout",
            { staticClass: "item__list", attrs: { wrap: "" } },
            [
              _vm._l(_vm.visibleSettings, function(visibleSetting, index) {
                return [
                  visibleSetting.visible
                    ? _c(
                        "v-flex",
                        {
                          key: index,
                          staticClass: "list__item",
                          attrs: { xs12: "" }
                        },
                        [
                          _c(
                            "v-layout",
                            {
                              attrs: {
                                wrap: "",
                                "align-center": "",
                                "justify-space-between": ""
                              }
                            },
                            [
                              _c("v-flex", { attrs: { xs6: "" } }, [
                                _c("span", [
                                  _vm._v(
                                    " " + _vm._s(visibleSetting.label) + " "
                                  ),
                                  _c("span", { staticClass: "item__sub" }, [
                                    _vm._v(_vm._s(visibleSetting.description))
                                  ])
                                ])
                              ]),
                              _c(
                                "v-flex",
                                {
                                  staticClass: "flex-starving",
                                  attrs: { xs6: "" }
                                },
                                [
                                  visibleSetting.type === "text"
                                    ? _c("span", { staticClass: "fadedText" }, [
                                        _vm._v(_vm._s(visibleSetting.value))
                                      ])
                                    : _vm._e(),
                                  visibleSetting.type === "text-field"
                                    ? _c("ATextInput", {
                                        attrs: { "hide-details": "" },
                                        on: {
                                          change: function($event) {
                                            return _vm.updateSetting(
                                              visibleSetting.key,
                                              visibleSetting.value
                                            )
                                          }
                                        },
                                        model: {
                                          value: visibleSetting.value,
                                          callback: function($$v) {
                                            _vm.$set(
                                              visibleSetting,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "visibleSetting.value"
                                        }
                                      })
                                    : _vm._e(),
                                  visibleSetting.type === "text-area"
                                    ? _c("v-textarea", {
                                        attrs: { "hide-details": "" },
                                        on: {
                                          change: function($event) {
                                            return _vm.updateSetting(
                                              visibleSetting.key,
                                              visibleSetting.value
                                            )
                                          }
                                        },
                                        model: {
                                          value: visibleSetting.value,
                                          callback: function($$v) {
                                            _vm.$set(
                                              visibleSetting,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "visibleSetting.value"
                                        }
                                      })
                                    : _vm._e(),
                                  visibleSetting.type === "switch"
                                    ? _c("v-switch", {
                                        staticClass: "custom_switch",
                                        attrs: {
                                          color: "#837f16",
                                          "hide-details": ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.updateSetting(
                                              visibleSetting.key,
                                              visibleSetting.value
                                            )
                                          }
                                        },
                                        model: {
                                          value: visibleSetting.value,
                                          callback: function($$v) {
                                            _vm.$set(
                                              visibleSetting,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "visibleSetting.value"
                                        }
                                      })
                                    : _vm._e(),
                                  visibleSetting.type === "checkbox"
                                    ? _c("v-checkbox", {
                                        attrs: {
                                          color: "#837f16",
                                          "hide-details": ""
                                        },
                                        on: {
                                          change: function($event) {
                                            return _vm.updateSetting(
                                              visibleSetting.key,
                                              visibleSetting.value
                                            )
                                          }
                                        },
                                        model: {
                                          value: visibleSetting.value,
                                          callback: function($$v) {
                                            _vm.$set(
                                              visibleSetting,
                                              "value",
                                              $$v
                                            )
                                          },
                                          expression: "visibleSetting.value"
                                        }
                                      })
                                    : _vm._e(),
                                  visibleSetting.type === "date"
                                    ? _c(
                                        "v-menu",
                                        {
                                          attrs: {
                                            "close-on-content-click": true,
                                            "nudge-right": 40,
                                            "offset-y": "",
                                            "min-width": "290px",
                                            transition: "scale-transition"
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function(ref) {
                                                  var on = ref.on
                                                  return [
                                                    _c(
                                                      "ATextInput",
                                                      _vm._g(
                                                        {
                                                          attrs: {
                                                            label: "Kies datum",
                                                            "prepend-outer-icon":
                                                              "event",
                                                            value: _vm.formattedDate(
                                                              visibleSetting.value
                                                            )
                                                          }
                                                        },
                                                        on
                                                      )
                                                    )
                                                  ]
                                                }
                                              }
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value: visibleSetting.menu,
                                            callback: function($$v) {
                                              _vm.$set(
                                                visibleSetting,
                                                "menu",
                                                $$v
                                              )
                                            },
                                            expression: "visibleSetting.menu"
                                          }
                                        },
                                        [
                                          _c("v-date-picker", {
                                            attrs: { "first-day-of-week": "1" },
                                            on: {
                                              change: function($event) {
                                                return _vm.updateSetting(
                                                  visibleSetting.key,
                                                  visibleSetting.value
                                                )
                                              },
                                              input: function($event) {
                                                visibleSetting.menu = false
                                              }
                                            },
                                            model: {
                                              value: visibleSetting.value,
                                              callback: function($$v) {
                                                _vm.$set(
                                                  visibleSetting,
                                                  "value",
                                                  $$v
                                                )
                                              },
                                              expression: "visibleSetting.value"
                                            }
                                          })
                                        ],
                                        1
                                      )
                                    : _vm._e()
                                ],
                                1
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    : _vm._e()
                ]
              })
            ],
            2
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }